.profile-page{
  min-height: 90vh;
}
/*Profile Card*/
.profile-card-3 {
    font-family: 'Open Sans', Arial, sans-serif;
    position: relative;
    float: left;
    overflow: hidden;
    width: 100%;
    text-align: center;
    height:368px;
    border:none;
    /* box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2); */
    box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.75);

  }
  .profile-card-3 .background-block {
      float: left;
      width: 100%;
      height: 200px;
      overflow: hidden;
  }
  .profile-card-3 .background-block .background {
    width:100%;
    height: auto;
    vertical-align: top;
    opacity: 1;
    -webkit-filter: blur(0.2px);
    filter: blur(0.2px);
     -webkit-transform: scale(2.8);
    transform: scale(2.8);
  }
  .profile-card-3 .card-content {
    width: 100%;
    padding: 15px 25px;
    color:#232323;
    float:left;
    background:#efefef;
    height:50%;
    border-radius:0 0 5px 5px;
    position: relative;
    z-index: 9999;
  }
  .profile-card-3 .card-content::before {
      content: '';
      background: #efefef;
      width: 105%;
      height: 100%;
      left: 11px;
      bottom: 51px;
      position: absolute;
      z-index: -1;
      transform: rotate(-13deg);
  }
  .profile-card-3 .profile {
    border-radius: 50%;
    position: absolute;
    bottom: 50%;
    left: 50%;
    max-width: 100px;
    opacity: 1;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(255, 255, 255, 1);
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    z-index:99999;
  }
  .profile-card-3 .profile:hover{
    border:2px solid #56527F;
    /* border: 2px solid #c79c38; */
    box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.75);
  }
  .profile-card-3 .card-content h2 {
    margin: 0 0 5px;
    font-weight: 600;
    font-size:30px;
  }
  .profile-card-3 .card-content p {
    display: block;
    font-size: 15px;
    margin-top:10px;
  }
  @media screen and (max-width: 768px){
    .account-details h5{
        font-size: 15px;
    }
  }
  @media screen and (max-width: 400px){
    .account-details h5{
        font-size: 11px;
    }
  }
  .profile-links{
    border-top: 1px solid black;
    padding: 20px 0;
  }
  a{
    text-decoration: none;
  }
  .profile-links a{
    background-color: #56527F;
    color: white;
    padding: 5px 20px;
    border-radius: 20px;
  }
  .profile-links a:hover{
    background-color: #c79c38;
    color: #efefef;
    transition: 300ms ease;
  }


  /* History Page Style  */
  .table-div{
    border-radius: 10px;
    box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.5);
    -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.5);
  }
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
  /* box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.5);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.5); */

  /* overflow-x: scroll; */
}


td, th {
  border-left: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 20%;
  max-width: 20%;

}
tr{
  font-size: 15px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
@media screen and (max-width: 768px){
  td, th {
    padding: 5px 7px;
  }
  tr{
    font-size: 12px;
  }
}
@media screen and (max-width: 500px){
  table{
    width: 150vw;
  }
  td, th {
    padding: 3px 5px;
  }
  tr{
    font-size: 10px;
  } 
  
  .p-scc-details-h{
    width: 100%;
  }
}



.profile-thumb-block{
  position: relative;
}
.input-container {
  display: none;
  position: absolute;
  top: -60px;
  left: 47%;
  padding: 0px;
  z-index: 99999;
  background: transparent;
}
.input-container label svg{
  color: black;
}
.profile-thumb-block:hover .input-container{
  display: block;
}

.p-scc-details-h{
  width: 70%;
}
