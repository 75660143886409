h1, h2, h3, h4, h5{
  color: #56527F;
}
.home-header-content h1, .home-header-content h5 i{
  color: white;
}
.img-div img{
  height: auto;
  width: 100%;
}
.text-blue{
  color: #56527F;
}

.btn-blue{
  background-color: #56527F;
  color: white;
  border-radius: 25px;
}
.btn-blue:hover,.btn-blue:focus, .btn-blue:active{
  background-color: #c79c38;
  color: white;
}

input[type=number]::-webkit-inner-spin-button, .input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.form-div{
  border: 1px solid #56527F;
  border-radius: 10px;
  box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
}
