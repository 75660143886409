/* Deposit Page Style */
.deposit-page{
  min-height: 90vh;
}
.form-div{
  border: 1px solid #56527F;
  border-radius: 10px;
  box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
}
.form-deposit input{
  width: 100%;
  border-radius: 10px;
  border: 1px solid #56527F;
  padding: 5px 10px;
  box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  margin-bottom: 20px;
}
.form-deposit input:hover, .form-deposit input:focus{
  border: 1px solid #56527F;
  outline: none;
}


input[type=number]::-webkit-inner-spin-button, .input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.copy-address{
  position: relative;

}
.copy-address input{
  width: 100%;
  border-radius: 10px;
  font-size: 20px;
  border: 1px solid #56527F;
  padding: 10px 20px;
  box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  margin-bottom: 20px;
}

.copy-address input:hover, .copy-address input:focus{
  border: 1px solid #56527F;
  outline: none;
}
.copy-address button{
  position: absolute;
  top: 12px;
  right: 10px;
  border: 1px solid #56527F;
  outline: none;
  border-radius: 5px;
  background: transparent;  
  box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
}


input[type=number]::-webkit-inner-spin-button, .input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.qr-code{
  width: 250px;
  border-radius: 10px;
  border: 1px solid #56527F;
  box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  margin-bottom: 20px;
}


.qr-code{
  width: 250px;
  border-radius: 10px;
  border: 1px solid #56527F;
  box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -webkit-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 1px 2px 14px 0px rgba(0,0,0,0.3);
  margin-bottom: 20px;
}


/* Payment Proof Page Style */

.text-blue{
  color: #56527F;
}

.btn-blue{
  background-color: #56527F;
  color: white;
  border-radius: 25px;
}

.image-uploader{
  min-width: 100%;
  min-height: 500px;
  background-color: rgb(128, 128, 128);
}

.image-preview{
  vertical-align: middle;
  margin: auto;
} 

.image-preview img{
  height: auto;
  width: 100%;
  border-radius: 10px;
}

.image-preview span{
  font-size: 20px;
  font-weight: 500;
  color: #56527F;
}

/*  */
.image-uploader {
  /* width: 400px; */
  height: 400px;
  border: 2px dashed #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-uploader.drag-over {
  border-color: #666;
}

.image-preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
}

.image-preview span {
  font-size: 18px;
  font-weight: bold;
  color: #666;
  margin-top: 10px;
}

@media screen and (min-width: 500px) {

  .image-uploader{
    width: 300px;
  }
}